import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

export interface APIData {
  data: QuestionerData;
}

export interface Answer {
  answer_id: number;
  answer_text: string;
}

export interface QuestionerData {
  answers: string[];
  id: string;
  last_update_by: string;
  question_en: string;
  question_id: number;
  status: boolean;
}

export interface QuestionerData extends Array<[]> {}
@Injectable()
export class QuestionerService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token');

  constructor(private http: HttpClient) {}

  public getData() {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http
      .get<APIData>(this.SERVICE_URL + 'api/hai-question/data', { headers })
      .pipe(retry(3), catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  public save(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http.post(this.SERVICE_URL + 'api/hai-question/save', data, {
      headers,
    });
  }

  public update(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http.put(this.SERVICE_URL + 'api/hai-question/update', data, {
      headers,
    });
  }
}
