import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import {ProductData} from './product.service';

export interface ProductVoucherData {
  voucherCode: string;
  voucherName: string;
  status: boolean;
  product: ProductData;
}

export interface ITableData {
  statusCode: number;
  status: string;
  message: string;
  data: {
    content: ProductVoucherData[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
  };
}
@Injectable()
export class ProductVoucherService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token');

  constructor(private http: HttpClient) {}

  public getData(status?: string, name?: string) {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });

    return this.http
      .get<ITableData>(this.SERVICE_URL + 'api/product-voucher', {
        headers,
      })
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  public getPredefinedVoucherProducts(): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http.get(this.SERVICE_URL + 'api/product?status=true&hasPredefinedVoucher=true', { headers });
  }

  public saveProductVoucher(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http.post(this.SERVICE_URL + 'api/product-voucher', data, { headers });
  }

  public editProduct(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    const id = data.get('id');
    if (!id) {
      console.log('ID missing from the data');
    }
    return this.http.put(this.SERVICE_URL + `api/product-voucher/${id}`, data, {
      headers,
    });
  }

  public deleteProduct(id: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http.delete(this.SERVICE_URL + `api/product-voucher/${id}`, {
      headers,
    });
  }
}
