<div
  bsModal
  #modalForm="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-primary" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Form {{ form }}</h4>
        <button
          type="button"
          class="close"
          (click)="modalForm.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form
          [formGroup]="benefitForm"
          (ngSubmit)="toSave()"
          class="form-horizontal"
        >
          <div class="form-group row">
            <label class="col-md-3 col-form-label">Name (EN)</label>
            <div class="col-md-9">
              <input
                placeholder="Name (EN)"
                formControlName="nameEn"
                class="form-control"
              />
              <span
                class="help-block"
                style="color: red"
                *ngIf="
                  submit && benefitForm.get('nameEn')?.hasError('required')
                "
              >
                Please enter name (EN)
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-form-label">Name (ID)</label>
            <div class="col-md-9">
              <input
                placeholder="Name (ID)"
                formControlName="nameId"
                class="form-control"
              />
              <span
                class="help-block"
                style="color: red"
                *ngIf="
                  submit && benefitForm.get('nameId')?.hasError('required')
                "
                >Please enter name (ID)</span
              >
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-form-label">Description (EN)</label>
          </div>
          <quill-editor formControlName="descriptionEn"></quill-editor>
          <span
            class="help-block"
            style="color: red"
            *ngIf="
              submit && benefitForm.get('descriptionEn')?.hasError('required')
            "
            >Please enter description (EN)</span
          >
          <br />
          <div class="form-group row">
            <label class="col-md-3 col-form-label">Description (ID)</label>
          </div>
          <quill-editor formControlName="descriptionId"></quill-editor>
          <span
            class="help-block"
            style="color: red"
            *ngIf="
              submit && benefitForm.get('descriptionId')?.hasError('required')
            "
            >Please enter description (ID)</span
          >
          <br />
          <div class="form-group row">
            <label class="col-md-3 col-form-label">Status</label>
            <div class="col-md-9 d-flex align-items-center">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="status"
                  [value]="true"
                  id="highlightYes"
                />
                <label class="form-check-label" for="statusActive"
                  >Active</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="status"
                  [value]="false"
                  id="highlightNo"
                />
                <label class="form-check-label" for="statusInactive"
                  >Inactive</label
                >
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="modalForm.hide()"
        >
          Close
        </button>
        <button
          type="submit"
          class="btn"
          [class]="form === 'Add' ? 'btn-success' : 'btn-info'"
          (click)="toSave()"
          *ngIf="!isLoading && form !== 'Preview'"
        >
          Submit
        </button>
        <button
          type="button"
          class="btn"
          [class]="form === 'Add' ? 'btn-success' : 'btn-info'"
          *ngIf="isLoading"
          disabled
        >
          Processing...
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <strong>BPJS Benefit</strong>
      </div>
      <div class="card-body">
        <div class="col-6 col-sm-4 col-md mb-3 mb-xl-0 text-left">
          <button
            type="button"
            class="btn btn-success btn-sm"
            (click)="viewModal(false)"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="fade-in">
  <c-card>
    <c-card-body>
      <c-row class="mb-3">
        <c-col md="4" class="offset-md-8">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="cil-search"></i></span>
            </div>
            <input
              cInput
              class="form-control"
              [(ngModel)]="filterQuery"
              placeholder="Search by Name"
            />
          </div>
        </c-col>
      </c-row>

      <table
        class="table table-striped table-hover table-sm table-responsive-lg"
        [mfData]="benefitData | bpjsBenefitFilter : filterQuery"
        #mf="mfDataTable"
        [mfRowsOnPage]="10"
      >
        <thead>
          <tr>
            <th style="text-align: center">
              <mfDefaultSorter by="No" #sortNo>
                No
                <span
                  *ngIf="sortNo.isSortedByMeAsc"
                  class="cil-arrow-top"
                  aria-hidden="true"
                ></span>
                <span
                  *ngIf="sortNo.isSortedByMeDesc"
                  class="cil-arrow-bottom"
                  aria-hidden="true"
                ></span>
              </mfDefaultSorter>
            </th>
            <th style="text-align: center">
              <mfDefaultSorter by="nameEn" #nameEn>
                Name (EN)
                <span
                  *ngIf="nameEn.isSortedByMeAsc"
                  class="cil-arrow-top"
                  aria-hidden="true"
                ></span>
                <span
                  *ngIf="nameEn.isSortedByMeDesc"
                  class="cil-arrow-bottom"
                  aria-hidden="true"
                ></span>
              </mfDefaultSorter>
            </th>
            <th style="text-align: center">
              <mfDefaultSorter by="nameId" #nameId>
                Name (ID)
                <span
                  *ngIf="nameId.isSortedByMeAsc"
                  class="cil-arrow-top"
                  aria-hidden="true"
                ></span>
                <span
                  *ngIf="nameId.isSortedByMeDesc"
                  class="cil-arrow-bottom"
                  aria-hidden="true"
                ></span>
              </mfDefaultSorter>
            </th>
            <th style="text-align: center">
              <mfDefaultSorter by="status" #status>
                Status
                <span
                  *ngIf="status.isSortedByMeAsc"
                  class="cil-arrow-top"
                  aria-hidden="true"
                ></span>
                <span
                  *ngIf="status.isSortedByMeDesc"
                  class="cil-arrow-bottom"
                  aria-hidden="true"
                ></span>
              </mfDefaultSorter>
            </th>
            <th style="text-align: center"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of mf.data; let i = index">
            <td style="width: 5%; text-align: center">
              {{ benefitData.indexOf(item) + 1 }}
            </td>
            <td style="width: 15%; text-align: left">{{ item.nameEn }}</td>
            <td style="width: 15%; text-align: left">{{ item.nameId }}</td>
            <td style="width: 10%; text-align: left">
              <span
                [ngStyle]="{ color: item.status === true ? 'green' : 'red' }"
                >{{ item.status === true ? "Active" : "Inactive" }}</span
              >
            </td>
            <td style="width: 10%; text-align: right">
              <div class="btn-group" dropdown title="Edit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-pencil-square"
                  viewBox="0 0 16 16"
                  style="cursor: pointer"
                  (click)="viewModal(item)"
                  title="Edit"
                >
                  <path
                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                  />
                </svg>
              </div>
              &nbsp;
              <div class="btn-group" dropdown title="Delete">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-trash"
                  viewBox="0 0 16 16"
                  style="cursor: pointer"
                  (click)="delete(item.id)"
                  title="Delete"
                >
                  <path
                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </div>
              &nbsp;
              <div class="btn-group" dropdown title="View">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-eye-fill"
                  viewBox="0 0 16 16"
                  style="cursor: pointer"
                  (click)="viewModal(item, 'Preview')"
                  title="View"
                >
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path
                    d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                  />
                </svg>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot style="border: none">
          <tr>
            <td colspan="7">
              <mfBootstrapPaginator></mfBootstrapPaginator>
            </td>
          </tr>
        </tfoot>
      </table>
    </c-card-body>
  </c-card>
</div>
