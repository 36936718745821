import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class StoriesPageService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token')

  constructor(
    private http: HttpClient
  ) { }

  public getStoriesPageByStoriesId(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.post(this.SERVICE_URL + "api/stories-page/get-by-stories-id", data , {headers});
  }

  public saveStoriesPage(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.post(this.SERVICE_URL + "api/stories-page/save", data , {headers});
  }

  // public editStoriesHeader(data: any): Observable<any> {
  //   const headers: HttpHeaders = new HttpHeaders({ 
  //     'Accept'        : 'application/json', 
  //     'Authorization' : 'Bearer '+this.TOKEN
  //   });
  //   return this.http.post(this.SERVICE_URL + "api/stories-header/update", data , {headers});
  // }

  public deleteStoriesPage(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.post(this.SERVICE_URL + "api/stories-page/delete", data , {headers});
  }
}
