import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import {
  ITableData,
  BpjsBenefitService,
  BenefitData,
} from 'src/app/services/bpjsBenefit.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-benefit',
  templateUrl: './benefit.component.html',
  styleUrls: ['./benefit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BenefitComponent implements OnInit {
  constructor(
    private bpjsBenefitService: BpjsBenefitService,
    private authService: AuthService
  ) {}

  error: any;
  @ViewChild('modalForm') public modalForm: ModalDirective;
  public data: ITableData[];
  public benefitData: BenefitData[] = [];
  public filterQuery = '';
  form = 'Add';
  isLoading = false;
  submit = false;
  showModal = false;

  benefitForm = new FormGroup({
    id: new FormControl(''),
    nameEn: new FormControl('', Validators.required),
    nameId: new FormControl('', Validators.required),
    descriptionEn: new FormControl('', Validators.required),
    descriptionId: new FormControl('', Validators.required),
    status: new FormControl('', Validators.required),
  });

  ngOnInit(): void {
    this.loadData();
  }

  delete(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete !',
    }).then((result) => {
      if (result.isConfirmed) {
        this.bpjsBenefitService.deleteBenefit(id).subscribe((res) => {
          this.loadData();
          Swal.fire({
            icon: 'success',
            title: 'Success',
            showConfirmButton: false,
            timer: 1500,
          });
        });
      }
    });
  }

  loadData() {
    this.bpjsBenefitService.getData().subscribe(
      (data: ITableData) => {
        setTimeout(() => {
          this.benefitData = [...data.data.content];
        }, 1000);
      }, // success path
      (error) => {
        console.error(error);
        if (error.status === 401) {
          this.authService.toExpiredLogout();
        }
      }
    );
  }

  viewModal(item: any, view: any = false) {
    this.submit = false;
    this.isLoading = false;
    this.form = item ? 'Edit' : 'Add';
    const {
      id = '',
      nameEn = '',
      nameId = '',
      descriptionEn = '',
      descriptionId = '',
      status = false,
    } = item || {};
    this.benefitForm.patchValue({
      id,
      nameEn,
      nameId,
      descriptionEn,
      descriptionId,
      status,
    });
    this.form = view || this.form;
    if (view) {
      this.benefitForm.get('nameEn')?.disable({ onlySelf: true });
      this.benefitForm.get('nameId')?.disable({ onlySelf: true });
      this.benefitForm.get('descriptionEn')?.disable({ onlySelf: true });
      this.benefitForm.get('descriptionId')?.disable({ onlySelf: true });
      this.benefitForm.get('status')?.disable({ onlySelf: true });
    } else {
      this.benefitForm.get('nameEn')?.enable();
      this.benefitForm.get('nameId')?.enable();
      this.benefitForm.get('descriptionEn')?.enable();
      this.benefitForm.get('descriptionId')?.enable();
      this.benefitForm.get('status')?.enable();
    }
    this.modalForm.show();
  }

  toSave() {
    this.submit = true;
    this.isLoading = true;

    if (this.benefitForm.invalid) {
      this.isLoading = false;
      return;
    }

    const data = {
      id: this.benefitForm.get('id')?.value,
      nameEn: this.benefitForm.get('nameEn')?.value,
      nameId: this.benefitForm.get('nameId')?.value,
      descriptionEn: this.benefitForm.get('descriptionEn')?.value,
      descriptionId: this.benefitForm.get('descriptionId')?.value,
      status: this.benefitForm.get('status')?.value,
    };

    if (this.form === 'Edit') {
      data.id;
      this.bpjsBenefitService.editBenefit(data).subscribe((res) => {
        this.loadData();
        this.isLoading = false;
        this.modalForm.hide();
        Swal.fire({
          icon: 'success',
          title: 'Success',
          showConfirmButton: false,
          timer: 1500,
        });
      });
    } else {
      this.bpjsBenefitService.saveBenefit(data).subscribe((res) => {
        this.loadData();
        this.isLoading = false;
        this.modalForm.hide();
        Swal.fire({
          icon: 'success',
          title: 'Success',
          showConfirmButton: false,
          timer: 1500,
        });
      });
    }
  }
}
