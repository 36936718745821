import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

export interface PaymentGateway {
  code: string;
  createdAt: string;
  id: number;
  name: string;
  status: boolean;
  updatedAt: string;
  updatedBy: string;
}

export interface PgPaymentMethod {
  bankCode: string;
  code: string;
  createdAt: string;
  flatFee: number;
  id: number;
  name: string;
  paymentGateway: PaymentGateway;
  percentageFee: number;
  processorCode: string;
  status: boolean;
  updatedAt: string;
  updatedBy: string;
}

export interface Member {
  uuid: string;
  fullName: string;
}

export interface PaymentTransaction {
  createdAt: string;
  grandTotal: number;
  id: number;
  invoiceNumber: string;
  member: Member;
  milesConversion: number;
  milesUsed: number;
  moneyInProcessorCode: string;
  paymentAmount: number;
  paymentDate: string;
  paymentExpiredAt: string;
  paymentStatus: string;
  pgPaymentMethod: PgPaymentMethod;
  referenceNumber: string;
  referralCode: string;
  serviceFee: number;
  transactionAmount: number;
  transactionCategory: string;
  transactionDoneAt: string;
  transactionStatus: string;
  updatedAt: string;
}

export interface Partner {
  banner: string;
  code: string;
  createdAt: string;
  descriptionEn: string;
  descriptionId: string;
  id: number;
  logo: string;
  name: string;
  pillarCategoryCode: string;
  status: boolean;
  updatedAt: string;
  updatedBy: string;
}

export interface ProductCategory {
  createdAt: string;
  id: number;
  nameEn: string;
  nameId: string;
  partner: Partner;
  status: boolean;
  updatedAt: string;
  updatedBy: string;
}

export interface Product {
  banner: string;
  basicPrice: number;
  code: string;
  createdAt: string;
  descEn: string;
  descId: string;
  id: number;
  isHighlighted: boolean;
  link: string;
  nameEn: string;
  nameId: string;
  productCategory: ProductCategory;
  sellingPrice: number;
  status: boolean;
  thumbnail: string;
  updatedAt: string;
  updatedBy: string;
}

export interface PaymentTransactionItem {
  doneAt: string;
  id: number;
  notes: string;
  paymentTransaction: PaymentTransaction;
  price: number;
  processorCode: string;
  product: Product;
  quantity: number;
  referenceEntity: string;
  referenceNumber: string;
  status: string;
}

export interface TransactionData {
  paymentTransaction: PaymentTransaction;
  paymentTransactionItem: PaymentTransactionItem[];
}

export interface ITableData {
  statusCode: number;
  status: string;
  message: string;
  data: {
    content: TransactionData[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
  };
}

@Injectable()
export class PaymentTransactionService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token');

  constructor(private http: HttpClient) {}

  public getTransactions(params?: HttpParams) {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });

    return this.http
      .get<ITableData>(this.SERVICE_URL + 'api/payment-transaction', {
        headers,
        params,
      })
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  public getTransactionsReport(params?: HttpParams): Observable<Blob> {
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + this.TOKEN,
      responseType: 'blob',
    });

    return this.http.get(this.SERVICE_URL + 'api/payment-transaction/report', {
      headers,
      params,
      responseType: 'blob',
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
