import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class DashboardLifestyleService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token')

  constructor(
    private http: HttpClient) { }

    public getWfbHistoryByGender(): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      return this.http.get(this.SERVICE_URL + "api/lifestyle-dashboard/getWfbHistoryByGender", {headers});
    }

    public getWfbByPriceRange(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let params = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/lifestyle-dashboard/getWfbByPriceRange", {headers: headers, params: params});
    }

    public getWfbByLocation(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let params = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/lifestyle-dashboard/getWfbByLocation", {headers: headers, params: params});
    }

    public getBBByAgeAndGender(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let params = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/lifestyle-dashboard/getBBByAgeAndGender", {headers: headers, params: params});
    }

    public getBBByOrderStatus(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let params = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/lifestyle-dashboard/getBBByOrderStatus", {headers: headers, params: params});
    }
}
