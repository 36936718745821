import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import {
  IPaymentMethodGroup,
  PaymentSetupService,
} from '../../services/paymentSetup.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-instruction',
  templateUrl: './payment-instruction.component.html',
  styleUrls: ['./payment-instruction.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentInstructionComponent implements OnInit {
  constructor(
    private paymentSetupService: PaymentSetupService,
    private authService: AuthService,
    private router: Router
  ) {}

  error: any;
  @ViewChild('modalForm') public modalForm: ModalDirective;
  public data: IPaymentMethodGroup[] = [];
  paymentList = [];
  extImage = ['image/png', 'image/jpg', 'image/jpeg'];

  form = 'Add';
  submit = false;
  isLoading = false;
  showModal = false;
  imageFile:
    | { link: string; file: any; name: string; type: string }
    | undefined;
  currentImageUrl: string = '';
  paymentMethodNames: { [key: string]: string } = {
    CC: 'Credit Card',
    VA: 'Virtual Account',
    QRIS: 'QRIS',
    FREE_PAYMENT: 'FREE_PAYMENT',
  };

  paymentInstructionForm = new FormGroup({
    id: new FormControl(''),
    logo: new FormControl(''),
    paymentInstructionInd: new FormControl(''),
    paymentInstructionEn: new FormControl(''),
    flatFee: new FormControl(0),
    percentageFee: new FormControl(0),
    name: new FormControl(''),
    status: new FormControl(true),
  });

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    this.paymentSetupService.getData().subscribe(
      (res) => {
        setTimeout(() => {
          this.data = res?.data;
          this.isLoading = false;
        }, 1000);
      }, // success path
      (error) => {
        console.log(error);
        if (error.status === 401) {
          this.authService.toExpiredLogout();
        }
      }
    );
  }

  imagesPreview(event: any) {
    if (event.target.files?.[0]) {
      const reader = new FileReader();

      reader.onload = (_event: any) => {
        this.imageFile = {
          link: _event.target.result,
          file: event.srcElement.files[0],
          name: event.srcElement.files[0].name,
          type: event.srcElement.files[0].type,
        };
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  viewModal(paymentMethod: any, view: any = false) {
    this.form = view ? 'Preview' : 'Edit';
    const {
      id = '',
      logo = '',
      paymentInstructionEn = '',
      paymentInstructionInd = '',
      flatFee = 0,
      percentageFee = 0,
      name = '',
      status = true,
    } = paymentMethod || {};
    this.paymentInstructionForm.patchValue({
      id,
      logo,
      paymentInstructionEn,
      paymentInstructionInd,
      flatFee,
      percentageFee,
      name,
      status,
    });
    this.currentImageUrl = paymentMethod.logo;
    if (view) {
      this.paymentInstructionForm
        .get('paymentInstructionEn')
        ?.disable({ onlySelf: true });
      this.paymentInstructionForm
        .get('paymentInstructionInd')
        ?.disable({ onlySelf: true });
    } else {
      this.paymentInstructionForm.get('paymentInstructionEn')?.enable();
      this.paymentInstructionForm.get('paymentInstructionInd')?.enable();
    }
    this.modalForm.show();
  }

  toSave() {
    this.submit = true;
    this.isLoading = true;

    if (this.paymentInstructionForm.invalid) {
      this.isLoading = false;
      return;
    }

    let data = new FormData();
    data.append(
      'paymentInstructionEn',
      this.paymentInstructionForm.get('paymentInstructionEn')?.value
    );
    data.append(
      'paymentInstructionInd',
      this.paymentInstructionForm.get('paymentInstructionInd')?.value
    );
    data.append('flatFee', this.paymentInstructionForm.get('flatFee')?.value);
    data.append(
      'percentageFee',
      this.paymentInstructionForm.get('percentageFee')?.value
    );
    data.append('name', this.paymentInstructionForm.get('name')?.value);
    data.append('status', this.paymentInstructionForm.get('status')?.value);

    if (this.imageFile && this.imageFile.file) {
      if (!this.extImage.includes(this.imageFile.type)) {
        Swal.fire({
          icon: 'error',
          title: 'File Image Not Image',
          showConfirmButton: false,
          timer: 1500,
        });
        this.isLoading = false;
        return;
      }
      if (this.imageFile.file.size >= 3000000) {
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: 'Size File Max 3MB !',
          showConfirmButton: false,
          timer: 1500,
        });
        this.isLoading = false;
        return;
      }
      data.append('logo', this.imageFile.file);
    }

    console.log('Form Data: ', {
      id: this.paymentInstructionForm.get('id')?.value,
      paymentInstructionEn: this.paymentInstructionForm.get(
        'paymentInstructionEn'
      )?.value,
      paymentInstructionInd: this.paymentInstructionForm.get(
        'paymentInstructionInd'
      )?.value,
      flatFee: this.paymentInstructionForm.get('flatFee')?.value,
      percentageFee: this.paymentInstructionForm.get('percentageFee')?.value,
      name: this.paymentInstructionForm.get('name')?.value,
      status: this.paymentInstructionForm.get('status')?.value,
      logo: this.imageFile ? this.imageFile.name : 'No new logo',
    });

    if (this.form === 'Edit') {
      data.append('id', this.paymentInstructionForm.get('id')?.value);
      this.paymentSetupService.editData(data).subscribe((res) => {
        this.loadData();
        this.isLoading = false;
        this.modalForm.hide();
        Swal.fire({
          icon: 'success',
          title: 'Success',
          showConfirmButton: false,
          timer: 1500,
        });
      });
    } else {
      this.paymentSetupService.saveData(data).subscribe((res) => {
        this.loadData();
        this.isLoading = false;
        this.modalForm.hide();
        Swal.fire({
          icon: 'success',
          title: 'Success',
          showConfirmButton: false,
          timer: 1500,
        });
      });
    }
  }

  getPaymentMethodName(enumValue: string): string {
    return this.paymentMethodNames[enumValue] || enumValue; // Fallback to the enum value if not found
  }

  goBack() {
    this.router.navigate(['/payment-setup']);
  }
}
