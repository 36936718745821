import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

export interface ITableData extends Array<[]> {}
@Injectable()
export class TheBridgeEpisodeService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token')

  constructor(
    private http: HttpClient
  ) { }

  public getTheBridgeByTheBridgeId(bridgeCatalogId:any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.get(this.SERVICE_URL + "api/lifestyle-the-bridge-episode/get/"+bridgeCatalogId, {headers});
  }

  public saveTheBridgeEpisode(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.post(this.SERVICE_URL + "api/lifestyle-the-bridge-episode/save", data , {headers});
  }

  public editTheBridgeEpisode(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.post(this.SERVICE_URL + "api/lifestyle-the-bridge-episode/update", data , {headers});
  }

  public deleteTheBridgeEpisode(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.post(this.SERVICE_URL + "api/lifestyle-the-bridge-episode/delete", data , {headers});
  }
}
