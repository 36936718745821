import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

export interface APIData {
  data: ContentData;
}

export interface ContentData {
  partner: PartnerObjectData;
  mappedPgPaymentMethodIds: [];
  allPgPaymentMethods: PaymentMethodData[];
}

export interface PaymentMethodData {
  paymentMethods: PaymentMethods;
}

export interface PaymentMethods {
  id: string;
  name: string;
  code: string;
  paymentGateway: PaymentGatewayObjectData;
  indexNumber: number;
}

export interface PartnerObjectData {
  name: string;
}

export interface PaymentGatewayObjectData {
  name: string;
}

export interface PaymentGroups {
  code: string;
  item: PaymentMethods[];
}

export interface PartnerObjectData extends Array<[]> {}
@Injectable()
export class MasterPartnerDetailService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token');

  constructor(private http: HttpClient) {}

  public getData(id: string = ''): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http
      .get<APIData>(
        this.SERVICE_URL + 'api/partner/' + id + '/pg-payment-methods',
        { headers }
      )
      .pipe(retry(3), catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  public save(data: any, id: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http.put(
      this.SERVICE_URL + 'api/partner/' + id + '/pg-payment-methods',
      data,
      {
        headers,
      }
    );
  }
}
