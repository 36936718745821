import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {catchError, map, retry} from 'rxjs/operators';

export interface UVCMerchantConfigData {
  id: number;
  brandCode: string;
  discountPercentage: number;
  amanMilesPercentage: number;
}

export interface UVCMerchantConfigData extends Array<[]> {}

@Injectable()
export class UVCMerchantConfigService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token');

  constructor(
    private http: HttpClient
  ) {
  }

  public getData() {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.TOKEN
    });
    return this.http.get<any>(
      this.SERVICE_URL + 'api/ultravoucher-configuration',
      {
        headers
      }).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError), // then handle the error
      map((response) => {
        return response.data.content || [];
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error);

    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  public saveUVCMerchantConfig(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http.post(this.SERVICE_URL + 'api/ultravoucher-configuration', data, { headers });
  }

  public editUVCMerchantConfig(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.TOKEN
    });
    const id = data.get('id');
    if (!id) {
      console.log('ID missing from the data');
    }
    return this.http.put(this.SERVICE_URL + `api/ultravoucher-configuration/${id}`, data, {headers});
  }

  public deleteUVCMerchantConfig(id: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.TOKEN
    });
    return this.http.delete(this.SERVICE_URL + `api/ultravoucher-configuration/${id}`, {headers});
  }
}
