import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

export interface IPaymentGateway {
  id: number;
  name: string;
  status: boolean;
}

export interface IPaymentMethod {
  id: number;
  name: string;
  status: boolean;
  flatFee: number;
  percentageFee: number;
  paymentGateway: IPaymentGateway;
  logo: string;
  paymentInstructionInd: string;
  paymentInstructionEn: string;
}

export interface IPaymentMethodGroup {
  paymentMethodEnum: string;
  paymentMethods: IPaymentMethod[];
}

export interface IPaymentMethodGroup extends Array<[]> {}
@Injectable()
export class PaymentSetupService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token');

  constructor(private http: HttpClient) {}

  public getData(): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http.get(this.SERVICE_URL + 'api/pg-payment-method', {
      headers,
    });
  }

  public saveData(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http.put(
      this.SERVICE_URL + 'api/pg-payment-method/bulk',
      data,
      {
        headers,
      }
    );
  }

  public editData(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    const id = data.get('id') as string;
    return this.http.put(
      this.SERVICE_URL + `api/pg-payment-method/${id}`,
      data,
      {
        headers,
      }
    );
  }
}
