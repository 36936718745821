import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import Quill from 'quill';

const Clipboard = Quill.import('modules/clipboard');

class CustomClipboard extends Clipboard {
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const Delta = Quill.import('delta');

    const range = this.quill.getSelection();
    const clipboardData = event.clipboardData;
    const plainText = clipboardData?.getData('text/plain') || '';
    const html = clipboardData?.getData('text/html') || '';

    if (range) {
      const copied = this.quill.clipboard.convert(html || plainText);

      const copiedDelta = copied.compose(
        new Delta().retain(copied.length(), {
          color: false,
          background: false,
        })
      );

      const delta = new Delta()
        .retain(range.index)
        .delete(range.length)
        .concat(copiedDelta);

      this.quill.updateContents(delta, 'user');
      this.quill.setSelection(delta.length() - range.length, 'silent');
      this.quill.scrollIntoView();
    }
  }
}

// Register the custom clipboard globally
Quill.register('modules/clipboard', CustomClipboard);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    QuillModule.forRoot({
      modules: {
        clipboard: true, // Just set clipboard to true to use the custom clipboard
      },
    }),
  ],
  exports: [QuillModule], // export QuillModule for use in other components
})
export class QuillCustomModule {}
