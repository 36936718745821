import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class UploadFileService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token')

  constructor(
    private http: HttpClient
  ) { }

 
public uploadFile(file: File, entity: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('entity', entity)
  
    const headers: HttpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.TOKEN
    });
    return this.http.post(this.SERVICE_URL + "api/upload-file", formData, { headers });
  }
}