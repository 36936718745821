import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'storiesHeaderFilter',
})
export class StoriesHeaderFilterPipe implements PipeTransform {
  transform(array: any[], query: string): any {
    if (query) {
      return _.filter(array, (row) => row.header_name.toLowerCase().indexOf(query.toLowerCase()) > -1);
    }
    return array;
  }
}
