import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentSetupMethod',
})
export class PaymentSetupMethodPipe implements PipeTransform {
  private readonly paymentMethods: { [key: string]: string } = {
    VA: 'Virtual Account',
    CC: 'Credit Card',
    QRIS: 'QRIS',
    EWALLET: 'E-wallet',
  };

  transform(value: string | undefined | null): any {
    if (!value) {
      return '';
    }
    return this.paymentMethods[value] || value;
  }
}
