<c-container>
    <c-row class="justify-content-center">
      <c-col md="8">
        <c-card-group>
          <c-card class="p-4">
            <alert type="{{typeAlert}}" *ngIf="message!==''">
              {{message}}
            </alert>
            <c-card-body>
              <form [formGroup]="authForm" (ngSubmit)="toLogin()">
                <h1>Login</h1>
                <p class="text-muted">Sign In to your account</p>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <c-icon name="cil-user"></c-icon>
                    </span>
                  </div>
                  <input cInput
                    placeholder="Username"
                    autocomplete="username"
                    required
                    formControlName="username"
                  />
                </div>
                <div class="input-group mb-4">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <c-icon name="cil-lock-locked"></c-icon>
                    </span>
                  </div>
                  <input cInput
                    type="password"
                    placeholder="Password"
                    autocomplete="current-password"
                    required
                    formControlName="password"
                  />
                </div>
                <c-row>
                  <c-col col="6">
                    <button cButton color="da-primary" class="px-5" type="submit" *ngIf="!load">Login</button>
                    <button cButton color="da-primary" class="px-5" disabled *ngIf="load">Processing...</button>
                  </c-col>
                  <c-col col="6" class="text-right">
                    <!-- <button cButton color="link" class="px-0">Forgot password?</button> -->
                  </c-col>
                </c-row>
              </form>
            </c-card-body>
          </c-card>
          <c-card
            class="text-white p-4 d-md-down-none"
            style="width: 100%;"
            style="background-color: #404040;"
            >
            <c-card-body class="text-center align-items-center">
              <div style="position: relative;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);">
                <h4>Content Management System</h4>
                <!-- <p>
                  Content Management System
                </p> -->
                <img 
                  src="assets/img/brand/download.svg" 
                  alt="Dompet AMAN" 
                  class="img-fluid"
                >
              </div>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
  </c-container>  