import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

export interface APIData {
  data: ContentData;
}

export interface ContentData {
  content: MasterPartnerData[];
}

export interface MasterPartnerData {
  banner: string;
  code: string;
  descriptionEn: string;
  descriptionId: string;
  id: number;
  logo: string;
  name: string;
  pillarCategoryCode: string;
  status: boolean;
}

export interface MasterPartnerCategoryData {
  nameId: string;
  nameEn: string;
  id: number;
}

export interface MasterPartnerData extends Array<[]> {}
@Injectable()
export class MasterPartnerService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token');

  constructor(private http: HttpClient) {}

  public getData() {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http
      .get<APIData>(this.SERVICE_URL + 'api/partner', { headers })
      .pipe(retry(3), catchError(this.handleError));
  }

  public getPartnerCategoryData() {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http
      .get<APIData>(this.SERVICE_URL + 'api/partner-category', { headers })
      .pipe(retry(3), catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  public save(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http.post(this.SERVICE_URL + 'api/partner', data, {
      headers,
    });
  }

  public update(data: any, id: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http.put(this.SERVICE_URL + 'api/partner/' + id, data, {
      headers,
    });
  }
}
