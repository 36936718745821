import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

export interface ITableData extends Array<[]> {}
@Injectable()
export class GeneralTransactionsService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token')

  constructor(
    private http: HttpClient
  ) { }

  public getGeneralTransactionsByInvoice(data:any) {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.get<ITableData>(this.SERVICE_URL + "api/transactionReport/get?"+data,{headers}).pipe(
      retry(3), 
      catchError(this.handleError)
    );
  }

  public exportExcelGeneralTransactionsByInvoice(data:any) :Observable<Blob> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Authorization' : 'Bearer '+this.TOKEN,
      'responseType'  : 'blob'
    });
    return this.http.get(this.SERVICE_URL + "api/transactionReport/exportToExcel?"+data, {'responseType' : 'blob',headers});
  }

  public exportExcelGeneralTransactionsByProduct(data:any) :Observable<Blob> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Authorization' : 'Bearer '+this.TOKEN,
      'responseType'  : 'blob'
    });
    return this.http.get(this.SERVICE_URL + "api/transactionItemReport/exportToExcel?"+data, {'responseType' : 'blob',headers});
  }

  public getGeneralTransactionsByProduct(data:any) {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.get<ITableData>(this.SERVICE_URL + "api/transactionItemReport/get?"+data,{headers}).pipe(
      retry(3), 
      catchError(this.handleError)
    );
  }

  public getDetails(id:String) {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.get(this.SERVICE_URL + "api/transactionReport/getDetails/"+id,{headers}).pipe(
      retry(3), 
      catchError(this.handleError) 
    );
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      return throwError(error);
    }
    return throwError('Something bad happened; please try again later.');
  }
}
