// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //Dev STX
  // apiUrl: 'http://streamworx.id:8889/'

  //Dev DA
   apiUrl: 'https://api-dev.dompetaman.com/api/v1/cms/',
   apiUrlPakDok: 'https://api.pakdok.com/v1.0/'

  //Local
  //  apiUrl: 'https://api-dev.dompetaman.com/api/v1/cms/',
  //  apiUrlPakDok: 'https://api.pakdok.com/v1.0/'

  //Local
  //  apiUrl: 'http://localhost:9445/api/v1/cms/',
  //  apiUrl: 'http://localhost:9445/',
  //  apiUrlPakDok: 'https://api.pakdok.com/v1.0/'

  //Prod DA
  // apiUrl: 'https://cms-api.dompetaman.com/',
  // apiUrlPakDok: 'https://api.pakdok.com/v1.0/'
}
// 89/'
// or easier debugging in development mode, you can import the following file
//  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//  *
//  * This import should be commented out in production mode because it will have a negative impact
//  * on performance if an error is thrown.
//  */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
