import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface ITableData extends Array<[]> {}

// @Injectable({providedIn:"root"})
@Injectable()
export class QuickIconService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token');

  constructor(private http: HttpClient) {}


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  public getData() {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http
      .get<ITableData>(this.SERVICE_URL + 'api/quickIcon/get', { headers })
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  public saveQuickIcon(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Accept'        : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.post(this.SERVICE_URL + "api/quickIcon/save", data , {headers});
  }

  public updateQuickIcon(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Accept'        : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.post(this.SERVICE_URL + "api/quickIcon/update", data , {headers});
  }

  public deleteQuickIcon(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Accept'        : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.post(this.SERVICE_URL + "api/quickIcon/delete", data , {headers});
  }

}
