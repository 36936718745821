<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <strong>Payment Instruction</strong>
      </div>
    </div>
  </div>
</div>

<div class="fade-in">
  <c-card>
    <c-body>
      <div *ngFor="let group of data" class="form-group form-section">
        <div class="col-md-12">
          <div class="section-title">
            {{ group.paymentMethodEnum }} ({{
              getPaymentMethodName(group.paymentMethodEnum)
            }})
          </div>
        </div>
        <table
          class="table table-striped table-hover table-sm table-responsive-lg"
        >
          <thead>
            <tr>
              <th style="width: 5%; text-align: center">No</th>
              <th class="text-center">Payment Method</th>
              <th style="width: 10%; text-align: center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let paymentMethod of group.paymentMethods; let i = index"
            >
              <td class="text-center">{{ i + 1 }}</td>
              <td>{{ paymentMethod.name }}</td>
              <td class="text-center">
                <div class="btn-group">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-pencil-square edit-icon"
                    viewBox="0 0 16 16"
                    (click)="viewModal(paymentMethod)"
                    title="Edit"
                  >
                    <path
                      d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                </div>
                <div class="btn-group">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-eye-fill view-icon"
                    viewBox="0 0 16 16"
                    (click)="viewModal(paymentMethod, 'Preview')"
                    title="View"
                  >
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                    <path
                      d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                    />
                  </svg>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6 col-sm-4 col-md mb-4 text-left">
        <button
          *ngIf="!isLoading"
          type="button"
          class="btn btn-success btn-sm"
          (click)="goBack()"
        >
          Back
        </button>
      </div>
    </c-body>
  </c-card>
</div>

<div
  bsModal
  #modalForm="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-primary" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Form {{ form }}</h4>
        <button
          type="button"
          class="close"
          (click)="modalForm.hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="paymentInstructionForm" class="form-horizontal">
          <div class="form-group row">
            <label class="col-md-3 col-form-label"
              >Payment Instruction (EN)</label
            >
          </div>
          <quill-editor formControlName="paymentInstructionEn"></quill-editor>
          <br />
          <div class="form-group row">
            <label class="col-md-3 col-form-label"
              >Payment Instruction (ID)</label
            >
          </div>
          <quill-editor formControlName="paymentInstructionInd"></quill-editor>
          <br />
          <div class="form-group row">
            <label class="col-md-3 col-form-label">Icon</label>
            <div class="col-md-9">
              <div *ngIf="currentImageUrl">
                <img
                  [src]="currentImageUrl"
                  alt="Payment Method Icon"
                  width="100"
                />
              </div>
              <div *ngIf="form != 'Preview'">
                <input
                  type="file"
                  (change)="imagesPreview($event)"
                  class="form-control"
                />
                <span class="help-block"
                  >* Max size 3 MB, suggested dimension 100 x 200 px</span
                >
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="modalForm.hide()"
        >
          Close
        </button>
        <button
          type="button"
          class="btn"
          [ngClass]="form === 'Add' ? 'btn-success' : 'btn-info'"
          (click)="toSave()"
          *ngIf="!isLoading && form != 'Preview'"
        >
          Save
        </button>
        <button
          type="button"
          class="btn"
          [class]="form === 'Add' ? 'btn-success' : 'btn-info'"
          *ngIf="isLoading"
          disabled
        >
          Processing...
        </button>
      </div>
    </div>
  </div>
</div>
