import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  LocationStrategy,
  HashLocationStrategy,
  DatePipe,
  CurrencyPipe,
} from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { BenefitComponent } from './components/bpjs/benefit.component';
import { PaymentSetupComponent } from './components/payment-setup/payment-setup.component';
import { PaymentInstructionComponent } from './components/payment-instruction/payment-instruction.component';

import { PaymentSetupMethodPipe } from './components/payment-setup/payment-setup-filter.pipe';
import { BpjsBenefitFilterPipe } from './components/bpjs/benefit-filter.pipe';

import { ServicesModule } from './services/services.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import {
  BadgeModule,
  ButtonModule,
  BreadcrumbModule,
  CardModule,
  CalloutModule,
  ChartModule,
  CollapseModule,
  DropdownModule,
  FormModule,
  GridModule,
  LayoutModule,
  ListGroupModule,
  ProgressModule,
  SharedModule,
  SidebarModule,
  SwitchModule,
  TabsetModule,
  TogglerModule,
} from '@coreui/angular';

import { AlertModule } from 'ngx-bootstrap/alert';

import {
  IconModule,
  IconSetModule,
  IconSetService,
} from '@coreui/icons-angular';

// 3rd party
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DataTableModule } from '@pascalhonegger/ng-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { QuillModule } from 'ngx-quill';

// Custom Modules
import { QuillCustomModule } from './modules/quill.module';

// Import containers
import { DefaultLayoutComponent } from './containers';
import { DefaultHeaderComponent } from './containers/default-layout/default-header/default-header.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { QuickIconService } from './services/quickicon.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const APP_CONTAINERS = [DefaultLayoutComponent, DefaultHeaderComponent];

@NgModule({
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    LoginComponent,
    HomeComponent,
    ShortNumberPipe,
    PaymentSetupComponent,
    PaymentSetupMethodPipe,
    BenefitComponent,
    BpjsBenefitFilterPipe,
    PaymentInstructionComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    AlertModule.forRoot(),
    TabsModule,
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    BadgeModule,
    BrowserAnimationsModule,
    ButtonModule,
    BreadcrumbModule,
    CardModule,
    CalloutModule,
    ChartModule,
    CollapseModule,
    DropdownModule,
    GridModule,
    IconModule,
    IconSetModule.forRoot(),
    SharedModule,
    LayoutModule,
    ListGroupModule,
    ProgressModule,
    SidebarModule,
    SwitchModule,
    TabsetModule,
    TogglerModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    FormModule,
    ReactiveFormsModule,
    ServicesModule.forRoot(),
    HttpClientModule,
    DataTableModule,
    QuillCustomModule,
  ],
  exports: [SharedModule],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    IconSetService,
    DatePipe,
    CurrencyPipe,
    QuickIconService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
