import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ProductCategoryData } from './productCategory.service';

export interface ProductData {
  banner: File;
  basicPrice: number;
  code: string;
  descEn: string;
  descId: string;
  id: number;
  link: string;
  nameEn: string;
  nameId: string;
  productCategory: ProductCategoryData;
  status: boolean;
  sellingPrice: number;
  thumbnail: File;
  isHighlighted: boolean;
  isOpenAmount: boolean;
}

export interface ITableData {
  statusCode: number;
  status: string;
  message: string;
  data: {
    content: ProductData[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
  };
}
@Injectable()
export class ProductService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token');

  constructor(private http: HttpClient) {}

  public getData() {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http
      .get<ITableData>(this.SERVICE_URL + 'api/product', { headers })
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  public getProducts(): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http.get(this.SERVICE_URL + 'api/product', { headers });
  }

  public saveProduct(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http.post(this.SERVICE_URL + 'api/product', data, { headers });
  }

  public editProduct(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    const id = data.get('id');
    if (!id) {
      console.log('ID missing from the data');
    }
    return this.http.put(this.SERVICE_URL + `api/product/${id}`, data, {
      headers,
    });
  }

  public deleteProduct(id: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.TOKEN,
    });
    return this.http.delete(this.SERVICE_URL + `api/product-/${id}`, {
      headers,
    });
  }
}
