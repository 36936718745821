<!--sidebar-->
<c-sidebar #sidebar="cSidebar" id="sidebar">
    <c-sidebar-brand
      [brandFull]="{
        src: 'assets/img/brand/logoda-white.png',
        width: 190,
        height: 46,
        alt: 'Dompet AMAN'
      }"
      [brandMinimized]="{
        src: 'assets/img/brand/logoda-icon.png',
        width: 46,
        height: 46,
        alt: 'Dompet AMAN'
      }"
    >
    </c-sidebar-brand>
    <c-sidebar-nav
      [navItems]="navItems"
      [perfectScrollbar]="perfectScrollbarConfig"
      dropdownMode="closeInactive"
    ></c-sidebar-nav>
    <c-sidebar-minimizer cSidebarMinimize="sidebar"></c-sidebar-minimizer>
  </c-sidebar>
  
  <!--main-->
  <c-wrapper>
    <!--header-->
    <app-default-header [fixed]="true"></app-default-header>
    
    <!--app body-->
    <c-body>
      <main class="c-main">
        <c-container [fluid]="true">
          <router-outlet></router-outlet>
        </c-container>
      </main>
      <!--app footer-->
    </c-body>
    
    <c-footer>
      <div class="mfs-auto">
        <a href="https://dompetaman.com/" target="_blank">
            Dompet AMAN
          </a>
          <span> &copy; 2021 Streamworx</span>
      </div>
    </c-footer>
  </c-wrapper>
  