import { Component, OnInit, ViewChild } from '@angular/core';
import { ITableData, StoriesHeaderService } from "../../services/storiesHeader.service";
import { StoriesPageService } from "../../services/storiesPage.service";
import { AuthService } from "../../services/auth.service";
import { FormControl, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-stories-header',
  templateUrl: './stories-header.component.html',
  styleUrls: ['./stories-header.component.scss']
})
export class StoriesHeaderComponent implements OnInit {

  constructor(
    private storiesHeaderService: StoriesHeaderService,
    private authService: AuthService,
    private storiesPageService: StoriesPageService
  ) { }
  
  error: any;
  @ViewChild('modalForm') public modalForm: ModalDirective;
  @ViewChild('modalPageForm') public modalPageForm: ModalDirective;
  public data: ITableData;
  public filterQuery = '';
  extImage=['image/png','image/jpg','image/jpeg']

  form = 'Add'
  dataStoriesPage=[]
  isLoading = false
  showModal=false
  imageFile: {link: string, file: any, name: string, type: string}
  imagePageFile: {link: string, file: any, name: string, type: string}
  stories_id=''
  
  storiesHeaderForm = new FormGroup({
    stories_id: new FormControl(''),
    header_name: new FormControl(''),
    stories_type: new FormControl('')
  });

  storiesPageForm = new FormGroup({
    header_name: new FormControl(''),
    page_id: new FormControl(''),
    content_type: new FormControl(''),
    stories_id: new FormControl(''),
    file: new FormControl(''),
    video_url: new FormControl('')
  });

  ngOnInit(): void {
    this.loadData()
  }

  toSavePage(){
    let data = new FormData()
    this.isLoading=true
    if(this.imagePageFile && this.storiesPageForm.get('content_type')?.value === 'image'){
      if(!this.extImage.includes(this.imagePageFile.type)){
        Swal.fire({
          icon: 'error',
          title: 'File Not Image',
          showConfirmButton: false,
          timer: 1500
        })
        this.isLoading=false
        return 
      }
    }
    if(this.imagePageFile.file.size >= 1000000){
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Size File Max 1MB !',
        showConfirmButton: false,
        timer: 1500
      })
      this.isLoading=false
      return
    }
    if(!this.storiesPageForm.get('content_type')?.value){
      Swal.fire({
        icon: 'error',
        title: 'Content Type Empty!',
        showConfirmButton: false,
        timer: 1500
      })
      this.isLoading=false
      return
    } 
    if(!this.storiesPageForm.get('file')?.value){
      Swal.fire({
        icon: 'error',
        title: 'File Empty!',
        showConfirmButton: false,
        timer: 1500
      })
      this.isLoading=false
      return
    } 
    data.append("media",this.imagePageFile.file)

    // else if(this.storiesPageForm.get('content_type')?.value === 'video'){
    //   data.append("video_url",this.storiesPageForm.get('video_url')?.value)
    // }
    data.append("content_type",this.storiesPageForm.get('content_type')?.value)
    data.append("stories_id",this.stories_id)
    this.storiesPageService.saveStoriesPage(data).subscribe(res => { 
      this.storiesPageForm.get('content_type')?.patchValue('')
      this.storiesPageForm.get('file')?.patchValue('')
      this.dataStoriesPage=res 
      this.isLoading=false
    })
    // this.modalPageForm.hide()
  }

  deletePage(id:any){
    Swal.fire({
      title: 'Are you sure?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete !'
    }).then((result) => {
      if (result.isConfirmed) {
        let data = new FormData()
        data.append('id', id)
        data.append('stories_id', this.stories_id)
        this.storiesPageService.deleteStoriesPage(data).subscribe(res => { 
          this.dataStoriesPage=res
          Swal.fire({
            icon: 'success',
            title: 'Success',
            showConfirmButton: false,
            timer: 1500
          })
        })
      }
    })
  }

  imagesPreviewImage(event:any) {
    if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();

        reader.onload = (_event: any) => {
            this.imageFile = {
                link: _event.target.result,
                file: event.srcElement.files[0],
                name: event.srcElement.files[0].name,
                type: event.srcElement.files[0].type
            };
        };
        reader.readAsDataURL(event.target.files[0]);
    }
  }

  imagesPreviewImagePage(event:any) {
    if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();

        reader.onload = (_event: any) => {
            this.imagePageFile = {
                link: _event.target.result,
                file: event.srcElement.files[0],
                name: event.srcElement.files[0].name,
                type: event.srcElement.files[0].type
            };
        };
        reader.readAsDataURL(event.target.files[0]);
    }
  }

  delete(id:any){
    Swal.fire({
      title: 'Are you sure?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete !'
    }).then((result) => {
      if (result.isConfirmed) {
        let data = new FormData()
        data.append('id', id)
        this.storiesHeaderService.deleteStoriesHeader(data).subscribe(res => { 
          this.loadData() 
          Swal.fire({
            icon: 'success',
            title: 'Success',
            showConfirmButton: false,
            timer: 1500
          })
        })
      }
    })
  }

  loadData(){
    this.storiesHeaderService.getData().subscribe(
      (data: ITableData) => {
        setTimeout(() => {
          this.data = [...data];
          
        }, 1000);
      }, // success path
      (error) => {
        console.log(error)
        if(error.status===401){
          this.authService.toExpiredLogout()
        } 
      }
    );
  }

  viewModal(item:any, view:any=false){
    this.form=item ? 'Edit' : 'Add'
    this.storiesHeaderForm.get('header_name')?.patchValue(!item ? '' : item.header_name)
    this.storiesHeaderForm.get('stories_id')?.patchValue(!item ? '' : item.stories_id)
    this.storiesHeaderForm.get('stories_type')?.patchValue(!item ? '' : item.stories_type)
    this.form=view ? view : this.form
    if(view){
      this.storiesHeaderForm.get('header_name')?.disable({ onlySelf: true })
      this.storiesHeaderForm.get('stories_type')?.disable({ onlySelf: true })
    } else {
      this.storiesHeaderForm.get('header_name')?.enable()
      this.storiesHeaderForm.get('stories_type')?.enable()
    }
    this.modalForm.show()
  }

  viewModalPage(item:any){
    this.storiesPageForm.get('header_name')?.patchValue(!item ? '' : item.header_name)
    this.storiesPageForm.get('file')?.patchValue('')
    this.storiesPageForm.get('header_name')?.disable({ onlySelf: true })

    this.modalPageForm.show()
    this.stories_id=item.stories_id
    this.loadDataStoriesPage()
  }

  loadDataStoriesPage(){
    let data = new FormData()
    data.append('stories_id', this.stories_id)
    this.storiesPageService.getStoriesPageByStoriesId(data).subscribe(
      res => {
        this.dataStoriesPage = res
      }
    )
  }

  toSave(){
    let data = new FormData()
    this.isLoading=true
    if(this.imageFile){
      if(!this.extImage.includes(this.imageFile.type)){
        Swal.fire({
          icon: 'error',
          title: 'File Not Image',
          showConfirmButton: false,
          timer: 1500
        })
        this.isLoading=false
        return 
      }
      if(this.imageFile.file.size >= 1000000){
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: 'Size File Max 1MB !',
          showConfirmButton: false,
          timer: 1500
        })
        this.isLoading=false
        return
      }
      data.append("image",this.imageFile.file)
    }
    
    data.append("header_name",this.storiesHeaderForm.get('header_name')?.value)
    data.append("stories_type",this.storiesHeaderForm.get('stories_type')?.value)

    if(this.form==='Edit'){
      data.append("stories_id",this.storiesHeaderForm.get('stories_id')?.value)
      this.storiesHeaderService.editStoriesHeader(data).subscribe(res => { 
        this.loadData() 
        this.isLoading=false
        this.modalForm.hide()
        Swal.fire({
          icon: 'success',
          title: 'Success',
          showConfirmButton: false,
          timer: 1500
        })
      })
    } else {
      this.storiesHeaderService.saveStoriesHeader(data).subscribe(res => { 
        this.loadData()
        this.isLoading=false
        this.modalForm.hide()
        Swal.fire({
          icon: 'success',
          title: 'Success',
          showConfirmButton: false,
          timer: 1500
        })
      })
    }
  }

}
