import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import {
  IPaymentMethodGroup,
  PaymentSetupService,
} from '../../services/paymentSetup.service';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-setup',
  templateUrl: './payment-setup.component.html',
  styleUrls: ['./payment-setup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentSetupComponent implements OnInit {
  constructor(
    private paymentSetupService: PaymentSetupService,
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  error: any;
  @ViewChild('modalForm') public modalForm: ModalDirective;
  @ViewChild('modalFormMember') public modalFormMember: ModalDirective;
  public filterQuery = '';
  public filterQueryMember = '';
  public data: IPaymentMethodGroup[] = [];

  paymentList = [];
  isLoading = false;
  showModal = false;

  paymentMethodForm = new FormGroup({
    payment_list: this.fb.array([]),
  });

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    this.paymentSetupService.getData().subscribe(
      (res) => {
        setTimeout(() => {
          this.data = res?.data;
          this.populateForm();
          this.isLoading = false;
        }, 500);
      }, // success path
      (error) => {
        console.log(error);
        if (error.status === 401) {
          this.authService.toExpiredLogout();
        }
      }
    );
  }

  populateForm() {
    const control = <FormArray>this.paymentMethodForm.get('payment_list');
    this.data.forEach((item, key) => {
      const paymentMethodsArray = this.fb.array([]);
      item.paymentMethods.forEach((paymentMethod) => {
        paymentMethodsArray.push(
          this.fb.group({
            flatFee: [paymentMethod.flatFee],
            id: [paymentMethod.id],
            name: [paymentMethod.name],
            percentageFee: [paymentMethod.percentageFee],
            status: [paymentMethod.status],
          })
        );
      });
      control.push(
        this.fb.group({
          paymentMethods: paymentMethodsArray,
        })
      );
    });
  }

  get paymentMethodEnumsControls() {
    return (this.paymentMethodForm.get('payment_list') as FormArray).controls;
  }

  getPaymentMethodsControls(index: number) {
    return (
      this.paymentMethodEnumsControls[index].get('paymentMethods') as FormArray
    ).controls;
  }

  getPaymentMethod(enumIndex: number, methodIndex: number) {
    const enumData = this.data[enumIndex];
    return enumData?.paymentMethods?.[methodIndex] || 'No data available';
  }

  toSave() {
    Swal.fire({
      title: 'Are you sure?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Save!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.isLoading = true;
        const formValues = this.paymentMethodForm.value;
        let request = formValues?.payment_list.flatMap(
          (item: any) => item.paymentMethods
        );

        this.paymentSetupService.saveData(request).subscribe((res) => {
          this.loadData();
        });
      }
    });
  }

  navigateToPaymentInstruction() {
    this.router.navigate(['/payment-instruction']);
  }
}
