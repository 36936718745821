<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <strong>Payment Setup</strong>
      </div>
      <div class="card-body">
        <form
          [formGroup]="paymentMethodForm"
          (ngSubmit)="toSave()"
          class="form-horizontal"
        >
          <div formArrayName="payment_list">
            <div
              *ngFor="let enumControl of data; let enumIndex = index"
              [formGroupName]="enumIndex"
              class="form-group form-section"
            >
              <div class="col-md-12 no-padding">
                <label class="col-md-12 no-padding">
                  <strong
                    >{{ data[enumIndex]?.paymentMethodEnum }} ({{
                      data[enumIndex]?.paymentMethodEnum | paymentSetupMethod
                    }})</strong
                  >
                </label>
              </div>
              <div formArrayName="paymentMethods" class="col-md-12">
                <div
                  *ngFor="
                    let methodControl of getPaymentMethodsControls(enumIndex);
                    let methodIndex = index
                  "
                  [formGroupName]="methodIndex"
                  class="form-group row col-form-label"
                >
                  <div class="col-md-5 no-padding">
                    <label class="col-form-label">
                      <input type="checkbox" formControlName="status" />
                      {{
                        getPaymentMethod(enumIndex, methodIndex)?.paymentGateway
                          ?.name
                      }}
                      - {{ getPaymentMethod(enumIndex, methodIndex)?.name }}
                    </label>
                  </div>

                  <div class="col-md-2 input-group">
                    <input
                      placeholder="Discount Percentage"
                      type="number"
                      formControlName="percentageFee"
                      class="form-control"
                      [readonly]="!methodControl.get('status')?.value"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <input
                      type="number"
                      formControlName="flatFee"
                      class="form-control"
                      [readonly]="!methodControl.get('status')?.value"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-4 col-md mb-3 mb-xl-0 text-left no-padding">
            <button
              type="submit"
              [disabled]="isLoading"
              class="btn btn-success btn-sm"
            >
              {{ isLoading ? "Processing..." : "Save" }}
            </button>
            <button
              *ngIf="!isLoading"
              type="button"
              class="btn btn-success btn-sm ml-2"
              (click)="navigateToPaymentInstruction()"
            >
              Set Payment Instruction
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
