import { Component, OnInit, HostBinding } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from "../../services/auth.service";
import { MenuService } from "../../services/menu.service";
import { Router,ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @HostBinding('class') cAppClass = 'c-app flex-row align-items-center';
  constructor(
    private authService: AuthService,
    private menuService: MenuService,
    private route: Router,
    private activatedRoute: ActivatedRoute
  ) { }
  authForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });
  load=false

  message = ''
  typeAlert = ''

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => this.message = params['message'] ? params['message'] : this.message);
    if(this.message){
      this.typeAlert='danger'
    }
  }

  toLogin(){
    this.load=true
    const formData = {
      "username" : this.authForm.get('username')?.value,
      "password" : this.authForm.get('password')?.value
    }
    const formDataPakDok = {
          "grant_type":"password",
          "username":"android",
          "password":"ba352ed2ef574612478c9b9952be142fe0755156BTI4M"
      }

      this.authService.login(formData).subscribe(response => {
        localStorage.setItem('token', response.token)
        this.menuService.getMenuByUser(this.authForm.get('username')?.value, response.token)?.subscribe(res => {
          this.load=false
          this.message='Success Login'
          this.typeAlert='success'
          localStorage.setItem('menu', JSON.stringify(res))
          let path:any = null
          res.forEach((element:any) => {
            if(element.menuParentId && !path && element.children.length <= 0){
              path = element.menuPath
            }
            if(element.children.length >= 1 && !path){
              element.children.forEach((lmnt:any) => {
                if(!path){
                  path = lmnt.menuPath
                }
              });
            }
          });
          this.route.navigate(['/dashboard']);
          return
        })
      },
      err => {
        this.load=false
        this.message = 'Username dan atau password Anda salah, silahkan coba lagi'
        this.typeAlert='danger'
      }
    )
    this.authService.loginPakDok(formDataPakDok).subscribe(response => {
      localStorage.setItem('token_pak_dok', response.data.access_token)
      console.log("status login pak dok sukses")
      console.log("data token : " + localStorage.getItem('token_pak_dok'));
    },
      err => {
        console.log("status login pak dok gagal")
      }
    )
  }
}
