import { Injectable } from '@angular/core';  
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';  
@Injectable({ providedIn: 'root' })  
export class AuthGuard implements CanActivate {  
    constructor(private _router: Router) { }  
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) { 
        // let path : any = []
        // let menu = JSON.parse(localStorage.getItem('menu') || '[]')
        // menu.forEach((element:any) => {
        //     if(element.readFlag){
        //         if(element.children.length >= 1){
        //             element.children.forEach((elmnt:any) => {
        //                 path.push('/'+elmnt.menuPath)
        //             });
        //         }
        //         path.push('/'+element.menuPath)
        //     }
        // });
        if (localStorage.getItem('token') 
        // && !path.includes(this._router.url)
        ) {  
            return true;  
        }
        this._router.navigate(['login']);  
        return false;  
    }  
} 