import { Component, OnInit, ViewChild,ViewEncapsulation } from '@angular/core';
import { ITableData, PushNotifService } from "../../services/pushNotif.service";
import { AuthService } from "../../services/auth.service";
import { TieringService } from "../../services/tiering.service";
import { MemberService } from "../../services/member.service";
import { FormControl, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-push-notif',
  templateUrl: './push-notif.component.html',
  styleUrls: ['./push-notif.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PushNotifComponent implements OnInit {

  constructor(
    private pushNotifService: PushNotifService,
    private authService: AuthService,
    private tieringService: TieringService,
    private memberService: MemberService
  ) { }
  
  error: any;
  @ViewChild('modalForm') public modalForm: ModalDirective;
  @ViewChild('modalFormMember') public modalFormMember: ModalDirective;
  public filterQuery = '';
  public filterQueryMember = '';
  public data: ITableData;
  public selectedCountries: Array<any> = [];
  public listTiering: Array<any> = [
    // { label: 'Belgium', value: 'BE' },
    // { label: 'Danmark', value: 'DK' },
    // { label: 'France', value: 'FR', disabled: true },
    // { label: 'Luxembourg', value: 'LU' },
    // { label: 'Netherlands', value: 'NL' },
  ];

  form = 'Add'
  dataMember=[]
  dataMemberView=[]
  dataMemberSend:any=[]
  dataMemberUuid:any=[]
  isLoading = false
  showModal=false
  
  pushNotifForm = new FormGroup({
    subject: new FormControl(''),
    content: new FormControl(''),
    recipientType: new FormControl(''),
    chooseTiering: new FormControl(''),
    memberUuid: new FormControl('')
  });

  ngOnInit(): void {
    this.loadData()
    this.loadDataTiering()
  }

  loadDataTiering(){
    this.tieringService.getTiering().subscribe(res => {
      this.listTiering=res
      this.listTiering.push({tier_code:'ALL',tier_description:'All Member'})
      // res.forEach((element:any) => {
      //   this.listTiering.push(element)
      //   console.log(element)
      // });
    })
  }

  viewModalSend(){
    this.modalForm.show()
    this.dataMemberUuid=[]
    this.dataMemberSend=[]
    this.filterQueryMember=''
    this.pushNotifForm.get('subject')?.patchValue('')
    this.pushNotifForm.get('content')?.patchValue('')
    this.pushNotifForm.get('recipientType')?.patchValue('')
    this.pushNotifForm.get('chooseTiering')?.patchValue([])
  }

  loadData(){
    this.pushNotifService.getData().subscribe(
      (data: ITableData) => {
        setTimeout(() => {
          this.data = [...data];
        }, 1000);
      }, // success path
      (error) => {
        console.log(error)
        if(error.status===401){
          this.authService.toExpiredLogout()
        } 
      }
    );
  }

  searchMember(){
    this.dataMemberView=[]
    this.dataMember.forEach(element => {
      let name : String = element['fullName'];
      if(name.toLowerCase().indexOf(this.filterQueryMember.toLowerCase()) > -1 ){
        this.dataMemberView.push(element)
      }
    });
  }

  chooseMember(){
    this.filterQueryMember=''
    this.modalFormMember.show()
    this.loadDataMember()
  }

  loadDataMember(){
    this.memberService.getMember().subscribe(
      res => {
        let tampungData:any=[]
        res.forEach((element:any) => {
          if(!this.dataMemberUuid.includes(element.uuid)){
            tampungData.push(element)
          }
        });
        this.dataMemberView=tampungData
        this.dataMember=tampungData
      }
    )
  }

  deleteMemberSend(uuid:any){
    let tampung:any = []
    this.dataMemberUuid.forEach((element:any) => {
      if(element!==uuid){
        tampung.push(element)
      }
    });
    this.dataMemberUuid=tampung
    tampung=[]
    this.dataMemberSend.forEach((element:any)=> {
      if(uuid!==element.uuid){
        tampung.push(element)
      }
    });
    this.dataMemberSend=tampung
  }

  addMemberSend(uuid:any, item:any){
    if(!this.dataMemberUuid.includes(uuid)){
      this.dataMemberSend.push(item)
      this.dataMemberUuid.push(uuid)
      let tampungData:any=[];
      this.dataMember.forEach((element:any) => {
        if(!this.dataMemberUuid.includes(element.uuid)){
          tampungData.push(element)
        }
      });
      this.dataMember=tampungData
      this.searchMember()
      Swal.fire({
        icon: 'success',
        title: 'Success Add Member',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Member is Already in The Queue',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }

  toSend(){
    let data = new FormData()
    this.isLoading=true
    if(!this.pushNotifForm.get('subject')?.value){
      Swal.fire({
        icon: 'error',
        title: 'Subject Not Empty!',
        showConfirmButton: false,
        timer: 1500
      })
      this.isLoading=false
      return
    }
    if(!this.pushNotifForm.get('content')?.value){
      Swal.fire({
        icon: 'error',
        title: 'Content Not Empty!',
        showConfirmButton: false,
        timer: 1500
      })
      this.isLoading=false
      return
    }
    // if(!this.pushNotifForm.get('recipientType')?.value){
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Send Type Not Empty!',
    //     showConfirmButton: false,
    //     timer: 1500
    //   })
    //   this.isLoading=false
    //   return
    // }
    
    data.append("subject",this.pushNotifForm.get('subject')?.value)
    data.append("content",this.pushNotifForm.get('content')?.value)
    let cekAllMember = 0
    this.pushNotifForm.get('chooseTiering')?.value.forEach((element:any) => {
      cekAllMember = element === 'ALL' ? 1 : cekAllMember
    });

    data.append("recipientType",cekAllMember===1?'ALL':'TIER')
    if(cekAllMember===0){
      if(this.pushNotifForm.get('chooseTiering')?.value.length <= 0){
        Swal.fire({
          icon: 'error',
          title: 'Choose Tier Not Empty!',
          showConfirmButton: false,
          timer: 1500
        })
        this.isLoading=false
        return
      }
      this.pushNotifForm.get('chooseTiering')?.value.forEach((element: any) => {
        data.append("tier[]",element)
      });
    }
    // return
    // if(this.pushNotifForm.get('recipientType')?.value==='TIER'){
    // }

    this.pushNotifService.sendNotif(data).subscribe(res => {
      Swal.fire({
        icon: 'success',
        title: 'Send Notification Success',
        showConfirmButton: false,
        timer: 1500
      })
      this.isLoading=false
      this.modalForm.hide()
      this.loadData()
      return 
    })
  }
}
