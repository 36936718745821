import { Component, OnInit } from '@angular/core';
import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./default-header.component.scss']
})
export class DefaultHeaderComponent extends HeaderComponent  implements OnInit {

  constructor(
    private classToggler: ClassToggleService,
    private route: Router
  ) {
    super();
  }

  ngOnInit(): void {
  }

  toggleTheme() {
    this.classToggler.toggle('.c-app', 'c-dark-theme');
  }

  logout(){
    Swal.fire({
      title: 'Are you sure?',
      text: "Logout!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Logout!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Logout!',
          'Logout Success.',
          'success'
        )
        localStorage.clear();
        this.route.navigate(['/login']);
      }
    })
  }

}
