import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

export interface ITableData extends Array<[]> {}
@Injectable()
export class TransactionReportService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token')

  constructor(
    private http: HttpClient
  ) { }

  public getTransactions(filterBy:String, startDate:String, endDate:String) {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.get<ITableData>(this.SERVICE_URL + "api/transactionReport/get?filterBy=" + filterBy + "&startDate=" + startDate + "&endDate=" + endDate,{headers}).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getDetails(id:String) {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.get<ITableData>(this.SERVICE_URL + "api/transactionReport/getDetails/"+id,{headers}).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  public getBlueBird(filterBy:String, startDate:String, endDate:String) {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.get<ITableData>(this.SERVICE_URL + "api/transactionBBReport/get?filterBy=" + filterBy + "&startDate=" + startDate + "&endDate=" + endDate + "",{headers}).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
            return throwError(error);

    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
