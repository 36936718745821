import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

export interface ITableData extends Array<[]> {}
@Injectable()
export class WfbimageService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token')

  constructor(
    private http: HttpClient
  ) { }

  public getWfbImageByCatalogId(catalogId:any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.get(this.SERVICE_URL + "api/lifestyle-wfb-image/get/"+catalogId, {headers});
  }

  public saveWfbImage(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Authorization' : 'Bearer '+this.TOKEN,
    });
    return this.http.post(this.SERVICE_URL + "api/lifestyle-wfb-image/save", data , {headers});
  }

  public deleteWfbImage(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.post(this.SERVICE_URL + "api/lifestyle-wfb-image/delete", data , {headers});
  }

  
}
