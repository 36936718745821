import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

export interface ITableData extends Array<[]> {}
@Injectable()
export class CatalogMasterService {
  private SERVICE_URL = environment.apiUrl;
  private SERVICE_URL_PAK_DOK = environment.apiUrlPakDok;
  private TOKEN = localStorage.getItem('token')
  private TOKEN_PAK_DOK = localStorage.getItem('token_pak_dok')

  constructor(
    private http: HttpClient
  ) { }

  public getData() {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.get<ITableData>(this.SERVICE_URL + "api/catalog-master/get",{headers}).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
            return throwError(error);

    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  public getCatalogMaster(): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.get(this.SERVICE_URL + "api/catalog-master/get", {headers});
  }

  public saveCatalogMaster(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.post(this.SERVICE_URL + "api/catalog-master/save", data , {headers});
  }

  public saveCatalogMasterForPakDok(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Authorization' : 'Bearer '+this.TOKEN_PAK_DOK,
    });
    var string = JSON.stringify(data);
    console.log("data dari service save PakDok : " + this.SERVICE_URL_PAK_DOK + "savecatalog/insert", data , {headers});
    return this.http.post(this.SERVICE_URL_PAK_DOK + "savecatalog/insert", data , {headers});
  }

  public getCatalogMasterByCategoryTypeName(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.post(this.SERVICE_URL + "api/catalog-master/get-by-category-name", data , {headers});
  }

  public editCatalogMaster(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.post(this.SERVICE_URL + "api/catalog-master/update", data , {headers});
  }

  public editCatalogMasterForPakDok(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Authorization' : 'Bearer '+this.TOKEN_PAK_DOK
    });
    var string = JSON.stringify(data);
    console.log("data dari service edit PakDok : " + this.SERVICE_URL_PAK_DOK + "savecatalog/update", data , {headers});
    return this.http.post(this.SERVICE_URL_PAK_DOK + "savecatalog/update", data , {headers});
  }

  public deleteCatalogMaster(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.post(this.SERVICE_URL + "api/catalog-master/delete", data , {headers});
  }

  public getDataWithFilter(pillar: any, status: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept'        : 'application/json', 
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.get(this.SERVICE_URL + "api/catalog-master/getCatalogMasterFilter?pillar_name=" + pillar + "&status=" + status, {headers});
  }

}
