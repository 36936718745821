import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  private SERVICE_URL = environment.apiUrl;
  private SERVICE_URL_PAK_DOK = environment.apiUrlPakDok;

  constructor(
    private http: HttpClient,
    private route: Router
  ) { }

  public login(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept': '*/*', 
      'Content-Type': 'application/json' 
    });
    return this.http.post<any>(this.SERVICE_URL + "authenticate", data);
  }

  public loginPakDok(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({ 
      'Accept': '*/*', 
      'Content-Type': 'application/json', 
      'Access-Control-Allow-Origin': '*'
    });
    return this.http.post(this.SERVICE_URL_PAK_DOK + "oauth/token", data);
  }

  public toExpiredLogout(){
    localStorage.clear();
    this.route.navigate(['/login','Expired Token']);
  }
}
