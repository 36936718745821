import { Component, HostBinding, OnInit } from '@angular/core';

import { navItems } from './_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
  @HostBinding('class.c-app') cAppClass = true;

  navItems :any = [];

  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };
  
  constructor() {
  }

  ngOnInit(): void {
    this.getMenu()
  }

  getMenu(){
    let menu = JSON.parse(localStorage.getItem('menu') || '[]')
    menu.forEach((element:any) => {
      if(!element.menuPath){
        this.navItems.push({name:element.menuTitle,title:true})
      } else {
        let children : any = []
        if(element.children.length >= 1){
          element.children.forEach((elmnt:any) => {
            if(elmnt.readFlag){
              children.push({name:elmnt.menuTitle, url: '/'+elmnt.menuPath})
            }
          });
        }
        this.navItems.push({name:element.menuTitle,url:'/'+element.menuPath,icon:element.menuIcon,children:children})
      }
    });
  }
}
