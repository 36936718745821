import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class DashboardTransactionsService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token')

  constructor(
    private http: HttpClient) { }

    public getTotalTransYTD(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let data = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/transactions-dashboard/totalRPTransactionYTD", {headers: headers, params: data});
    }

    public getCountTransYTD(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let data = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/transactions-dashboard/countTransactionYTD", {headers: headers, params: data});
    }

    public getTotalTransMTD(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let data = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/transactions-dashboard/totalRPTransactionMTD", {headers: headers, params: data});
    }

    public getCountTransMTD(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let data = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/transactions-dashboard/countTransactionMTD", {headers: headers, params: data});
    }

    public getCatTrans(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let data = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/transactions-dashboard/getCategoriesTransactions", {headers: headers, params: data});
    }

    public getTransHistory(): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      return this.http.get(this.SERVICE_URL + "api/transactions-dashboard/getTransactionHistory", {headers});
    }

    public getTransPilnPayStat(filterBy: any, startDate: any, endDate: any, pilar: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let data = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate)
        .set('pilar', pilar);
      return this.http.get(this.SERVICE_URL + "api/transactions-dashboard/getTransByPilarAndPaymentStatus", {headers: headers, params: data});
    }

    public getTransByMethodAndChannel(filterBy: any, startDate: any, endDate: any, paymentChannel: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let data = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate)
        .set('paymentChannel', paymentChannel);
      return this.http.get(this.SERVICE_URL + "api/transactions-dashboard/getTransByMethodAndChannel", {headers: headers, params: data});
    }

    public getTop5PurchItems(): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      return this.http.get(this.SERVICE_URL + "api/transactions-dashboard/top5PurchasedItems", {headers});
    }

    public getTop5Member(): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      return this.http.get(this.SERVICE_URL + "api/transactions-dashboard/top5Members", {headers});
    }
}
