<div bsModal #modalForm="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-primary" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Form Send</h4>
                <button type="button" class="close" (click)="modalForm.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="pushNotifForm" (ngSubmit)="toSend()" class="form-horizontal">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Subject</label>
                        <div class="col-md-9">
                            <textarea class="form-control" formControlName="subject" cols="5" rows="3" placeholder="Subject"></textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Content</label>
                        <div class="col-md-9">
                            <textarea class="form-control" formControlName="content" cols="5" rows="3" placeholder="Content"></textarea>
                        </div>
                    </div>
                    <!-- <div class="form-group row">
                        <label class="col-md-3 col-form-label">Send Type (*)</label>
                        <div class="col-md-9">
                            <select formControlName="recipientType" class="form-control">
                                <option value="">-- Choose Send Type --</option>
                                <option value="ALL">All Member</option>
                                <option value="TIER">Choose Tier</option>
                            </select>
                        </div> -->
                        <!-- <div class="col-md-3" *ngIf="pushNotifForm.get('recipientType')?.value==='SELECTION'">
                            <button
                                type="button"
                                class="btn btn-sm btn-info"
                                (click)="chooseMember()"
                            >Choose Member</button>
                        </div> -->
                    <!-- </div> -->
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Tier (*)</label>
                        <div class="col-md-9">
                            <ng-select
                                class="bootstrap"
                                placeholder="Choose tiering"
                                [items]="listTiering"
                                [multiple]="true"
                                bindLabel="tier_description"
                                bindValue="tier_code"
                                formControlName="chooseTiering">
                            </ng-select>
                        </div>
                    </div>
                    <!-- <div class="form-group row">
                        <label class="col-md-3 col-form-label"></label>
                        <div class="col-md-9">
                            <button
                                type="button"
                                class="btn btn-sm btn-primary"
                                (click)="toSend()"
                            >Send Notif</button>
                        </div>
                    </div> -->
                </form>
                <table class="table table-striped table-hover table-sm table-responsive-lg" *ngIf="pushNotifForm.get('recipientType')?.value==='SELECTION'">
                    <thead>
                        <tr>
                            <th style="width: 20%; text-align: left;">Name</th>
                            <th style="width: 20%; text-align: left;">Phone</th>
                            <th style="width: 20%; text-align: left;">Email</th>
                            <th style="width: 20%; text-align: left;">Gender</th>
                            <th style="width: 20%; text-align: left;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of dataMemberSend">
                            <td style="text-align: left;">{{ item.fullName }}</td>
                            <td style="text-align: left;">{{ item.phoneNumber }}</td>
                            <td style="text-align: left;">{{ item.email }}</td>
                            <td style="text-align: left;">{{ item.gender === 'MALE' ? 'Male' : 'Female' }}</td>
                            <td style="text-align: left;">
                                <button type="button" class="btn btn-danger" (click)="deleteMemberSend(item['uuid'])">
                                    Delete Queue
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="modalForm.hide()">Close</button>
                <button type="submit" class="btn" [class]="'btn-success'" (click)="toSend()" *ngIf="!isLoading">Send</button>
                <button type="button" class="btn" [class]="'btn-success'" *ngIf="isLoading" disabled>Processing...</button>
            </div>
        </div>
    </div>
</div>

<div bsModal #modalFormMember="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-primary" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Choose Member</h4>
                <button type="button" class="close" (click)="modalFormMember.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <c-row class="mb-3">
                    <c-col md="4" class="offset-md-8">
                    <div class="input-group">
                        <div class="input-group-prepend">
                        <span class="input-group-text"><i class="cil-search"></i></span>
                        </div>
                        <input cInput
                            class="form-control"
                            [(ngModel)]="filterQueryMember"
                            placeholder="Search by Name"
                            (input)="searchMember()"
                        />
                    </div>
                    </c-col>
                </c-row>
                <table
                    class="table table-striped table-hover table-sm table-responsive-lg"
                >
                    <thead>
                        <tr>
                            <th style="width: 20%; text-align: left;">Name</th>
                            <th style="width: 20%; text-align: left;">Phone</th>
                            <th style="width: 20%; text-align: left;">Email</th>
                            <th style="width: 20%; text-align: left;">Gender</th>
                            <th style="width: 20%; text-align: left;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of dataMemberView">
                            <td style="text-align: left;">{{ item['fullName'] }}</td>
                            <td style="text-align: left;">{{ item['phoneNumber'] }}</td>
                            <td style="text-align: left;">{{ item['email'] }}</td>
                            <td style="text-align: left;">{{ item['gender'] === 'MALE' ? 'Male' : 'Female' }}</td>
                            <td style="text-align: left;">
                                <button type="button" class="btn btn-primary" (click)="addMemberSend(item['uuid'], item)">
                                    Choose
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="modalFormMember.hide()">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
              <strong>Push Notification</strong>
            </div>
            <div class="card-body">                
                <div class="col-6 col-sm-4 col-md mb-3 mb-xl-0 text-left">
                    <button type="button" class="btn btn-success btn-sm" (click)="viewModalSend()">Send Notification</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="fade-in">
    <c-card>
        <c-card-body>
            <c-row class="mb-3">
                <c-col md="4" class="offset-md-8">
                <div class="input-group">
                    <div class="input-group-prepend">
                    <span class="input-group-text"><i class="cil-search"></i></span>
                    </div>
                    <input cInput
                        class="form-control"
                        [(ngModel)]="filterQuery"
                        placeholder="Search by Subject"
                    />
                </div>
                </c-col>
            </c-row>
            <table
                class="table table-striped table-hover table-sm table-responsive-lg"
                [mfData]="data | pushNotifFilter: filterQuery"
                #mf="mfDataTable"
                [mfRowsOnPage]="10"
            >
                <thead>
                    <tr>
                        <th style="width: 5%; text-align: center;">No</th>
                        <th style="width: 25%; text-align: center;">
                            <mfDefaultSorter by="subject" #sortSubject>
                                Subject
                                <span
                                    *ngIf="sortSubject.isSortedByMeAsc"
                                    class="cil-arrow-top"
                                    aria-hidden="true"
                                ></span>
                                <span
                                    *ngIf="sortSubject.isSortedByMeDesc"
                                    class="cil-arrow-bottom"
                                    aria-hidden="true"
                                ></span>
                            </mfDefaultSorter>
                        </th>
                        <th style="width: 40%; text-align: center;">
                            <mfDefaultSorter by="content" #sortContent>
                                Content
                                <span
                                    *ngIf="sortContent.isSortedByMeAsc"
                                    class="cil-arrow-top"
                                    aria-hidden="true"
                                ></span>
                                <span
                                    *ngIf="sortContent.isSortedByMeDesc"
                                    class="cil-arrow-bottom"
                                    aria-hidden="true"
                                ></span>
                            </mfDefaultSorter>
                        </th>
                        <th style="width: 10%; text-align: center;">
                            <mfDefaultSorter by="type" #sortType>
                                Pilar
                                <span
                                    *ngIf="sortType.isSortedByMeAsc"
                                    class="cil-arrow-top"
                                    aria-hidden="true"
                                ></span>
                                <span
                                    *ngIf="sortType.isSortedByMeDesc"
                                    class="cil-arrow-bottom"
                                    aria-hidden="true"
                                ></span>
                            </mfDefaultSorter>
                        </th>
                        <th style="width: 20%; text-align: center;">
                            <mfDefaultSorter by="sentDate" #sortSentDate>
                                Sent Date
                                <span
                                    *ngIf="sortSentDate.isSortedByMeAsc"
                                    class="cil-arrow-top"
                                    aria-hidden="true"
                                ></span>
                                <span
                                    *ngIf="sortSentDate.isSortedByMeDesc"
                                    class="cil-arrow-bottom"
                                    aria-hidden="true"
                                ></span>
                            </mfDefaultSorter>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of mf.data">
                        <td style="text-align: center;">{{data.indexOf(item) + 1}}</td>
                        <td style="text-align: left;">{{ item.subject | titlecase }}</td>
                        <td style="text-align: left;">{{ item.content | titlecase }}</td>
                        <td style="text-align: left;">{{ item.recipientType | titlecase }}</td>
                        <td style="text-align: left;">{{ item.sentDate | date: 'dd MMMM yyyy HH:mm:ss' }}</td>
                    </tr>
                </tbody>
                <tfoot style="border: none;">
                    <tr>
                        <td colspan="4">
                            <mfBootstrapPaginator></mfBootstrapPaginator>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </c-card-body>
    </c-card>
</div>
  