import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bpjsBenefitFilter',
})
export class BpjsBenefitFilterPipe implements PipeTransform {
  transform(array: any[], query: string): any {
    if (query) {
      return _.filter(
        array,
        (row) =>
          row.nameEn?.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          row.nameId?.toLowerCase().indexOf(query.toLowerCase()) > -1
      );
    }
    return array;
  }
}
