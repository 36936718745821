import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class DashboardUserActivityService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token')

  constructor(
    private http: HttpClient) { }

    public getAllUser(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let data = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/user-activity-dashboard/countAllUser", {headers: headers, params: data});
    }

    public getNewUser(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let data = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/user-activity-dashboard/countNewUser", {headers: headers, params: data});
    }

    public getPageViews(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let data = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/user-activity-dashboard/countPageViews", {headers: headers, params: data});
    }

    public getUserActions(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let data = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/user-activity-dashboard/getUserActions", {headers: headers, params: data});
    }

    public getUserActivityByGender(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let data = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/user-activity-dashboard/getUserActivityByGender", {headers: headers, params: data});
    }

    public getUserActivityByAge(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let data = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/user-activity-dashboard/getUserActivityByAge", {headers: headers, params: data});
    }

    public getUserActivityByPilar(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let data = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/user-activity-dashboard/getUserActivityByPilar", {headers: headers, params: data});
    }

    public getUserActivityByPilarCategory(filterBy: any, startDate: any, endDate: any): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      let data = new HttpParams()
        .set('filterBy', filterBy)
        .set('startDate', startDate)
        .set('endDate', endDate);
      return this.http.get(this.SERVICE_URL + "api/user-activity-dashboard/getUserActivityByPilarCategory", {headers: headers, params: data});
    }

    public getUserActivityByDays(): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      return this.http.get(this.SERVICE_URL + "api/user-activity-dashboard/getUserActivityByDays", {headers: headers});
    }

    public getUserActivityByHours(): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      return this.http.get(this.SERVICE_URL + "api/user-activity-dashboard/getUserActivityByHours", {headers: headers});
    }

    public getUserActivityByDates(): Observable<any> {
      const headers: HttpHeaders = new HttpHeaders({
        'Accept'        : 'application/json',
        'Content-Type'  : 'application/json',
        'Authorization' : 'Bearer '+this.TOKEN
      });
      return this.http.get(this.SERVICE_URL + "api/user-activity-dashboard/getUserActivityByDates", {headers: headers});
    }
}
