import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { DefaultLayoutComponent } from './containers';
import { AuthGuard } from './services/auth-guard.service';
import {BenefitComponent} from "./components/bpjs/benefit.component";
import {DashboardComponent} from "./components/dashboard/dashboard.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login',
    },
  },
  {
    path: 'login/:message',
    component: LoginComponent,
    data: {
      title: 'Login',
    },
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'dashboard/health',
        loadChildren: () =>
          import('./components/dashboard/health/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'dashboard/chart',
        loadChildren: () =>
          import('./components/dashboard/chartjs/chartjs.module').then(
            (m) => m.ChartjsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'report/transaction',
        loadChildren: () =>
          import('./components/report/transaction/transaction.module').then(
            (m) => m.TransactionModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'report/transaction/detail/:id',
        loadChildren: () =>
          import(
            './components/report/transactiondetail/transactiondetail.module'
          ).then((m) => m.TransactionDetailModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'report/bluebird',
        loadChildren: () =>
          import('./components/report/bluebird/bluebird.module').then(
            (m) => m.BlueBirdModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'report/general-transaction',
        loadChildren: () =>
          import(
            './components/report/general-transaction/general-transaction.module'
          ).then((m) => m.GeneralTransactionModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'report/product-partner-transaction',
        loadChildren: () =>
          import(
            './components/report/product-partner-transaction/product-partner-transaction.module'
          ).then((m) => m.ProductPartnerTransactionModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'members',
        loadChildren: () =>
          import('./components/members/members.module').then(
            (m) => m.MembersModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'tools',
        loadChildren: () =>
          import('./components/tools/tools.module').then((m) => m.ToolsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'catalog',
        loadChildren: () =>
          import('./components/catalog/catalog.module').then(
            (m) => m.CatalogModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'master-data',
        loadChildren: () =>
          import('./components/master-data/master-data.module').then(
            (m) => m.MasterDataModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import('./components/user-management/user-management.module').then(
            (m) => m.UserManagementModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'lifestyle',
        loadChildren: () =>
          import('./components/lifestyle/lifestyle.module').then(
            (m) => m.LifestyleModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'welcome-screen',
        loadChildren: () =>
          import('./components/welcome-screen/welcome-screen.module').then(
            (m) => m.WelcomeScreenModule
          ),
      },
      {
        path: 'bpjs',
        component: BenefitComponent,
        data: {
          title: 'BPJS Benefit',
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          title: 'Welcome Screen',
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'payment',
        loadChildren: () =>
          import('./components/payment/payment.module').then(
            (m) => m.PaymentModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'hai-membership',
        loadChildren: () =>
          import('./components/hai-membership/hai-membership.module').then(
            (m) => m.HaiMembershipDataModule
          ),
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
