<div bsModal #modalPageForm="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-primary" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Add Stories Page</h4>
                <button type="button" class="close" (click)="modalPageForm.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="storiesPageForm" (ngSubmit)="toSavePage()" class="form-horizontal">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Stories Name</label>
                        <div class="col-md-9">
                            <input placeholder="Enter the stories name" formControlName="header_name" class="form-control">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Content Type</label>
                        <div class="col-md-9">
                            <select class="form-control" formControlName="content_type">
                                <option value="">-- Choose Content Type --</option>
                                <option [value]="'video'">Video</option>
                                <option [value]="'image'">Image</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="storiesPageForm.value.content_type">
                        <label class="col-md-3 col-form-label">{{ storiesPageForm.value.content_type | titlecase }}</label>
                        <div class="col-md-9">
                            <input 
                                type="file" 
                                (change)="imagesPreviewImagePage($event)" 
                                formControlName="file"
                            >
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="storiesPageForm.value.content_type">
                        <label class="col-md-3 col-form-label"></label>
                        <div class="col-md-9">
                            <span>* Pastikan Video Durasi maksimal 15 detik, dan orientasi video vertikal</span>
                            <br>
                            <span>* Pastikan Orientasi Image vertikal</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-9">
                            <button type="submit" class="btn" [class]="form === 'Add' ? 'btn-success' : 'btn-info'" (click)="toSavePage()" *ngIf="!isLoading">Submit</button>
                            <button type="button" class="btn" [class]="form === 'Add' ? 'btn-success' : 'btn-info'" *ngIf="isLoading" disabled>Processing...</button>
                        </div>
                    </div>
                    <!-- <div class="form-group row" *ngIf="storiesPageForm.value.content_type=='video'">
                        <label class="col-md-3 col-form-label">Video</label>
                        <div class="col-md-9">
                            <input 
                                type="text" 
                                formControlName="video_url"
                                class="form-control"
                                placeholder="Video URL"
                            >
                        </div>
                    </div> -->
                </form>
                <hr>
                <table class="table table-striped table-hover table-sm table-responsive-lg">
                    <thead>
                        <tr>
                            <th style="width: 5%; text-align: center;">No</th>
                            <th>Content Type</th>
                            <th>Download Content</th>
                            <th>Created At</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of dataStoriesPage; let i = index;">
                            <td style="text-align: center;">{{i+1}}</td>
                            <td>{{item['content_type']}}</td>
                            <td>
                                <a 
                                    class="btn btn-sm btn-primary" href="{{item['content_url']}}" target="_blank" rel="noopener noreferrer"
                                >View Stories</a>
                            </td>
                            <td>{{ item['created'] | date : 'dd MMM yyyy HH:mm:ss' }}</td>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-danger"
                                    (click)="deletePage(item['page_id'])"
                                >Delete</button>
                            </td>
                        </tr>
                    </tbody>
                  </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="modalPageForm.hide()">Close</button>
                <!-- <button type="submit" class="btn" [class]="form === 'Add' ? 'btn-success' : 'btn-info'" (click)="toSavePage()" *ngIf="!isLoading">Submit</button>
                <button type="button" class="btn" [class]="form === 'Add' ? 'btn-success' : 'btn-info'" *ngIf="isLoading" disabled>Processing...</button> -->
            </div>
        </div>
    </div>
</div>

<div bsModal #modalForm="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-primary" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Form {{form}}</h4>
                <button type="button" class="close" (click)="modalForm.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="storiesHeaderForm" (ngSubmit)="toSave()" class="form-horizontal">
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Name</label>
                        <div class="col-md-9">
                            <input placeholder="Enter the stories name" formControlName="header_name" class="form-control">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Pilar</label>
                        <div class="col-md-9">
                            <select class="form-control" formControlName="stories_type">
                                <option value="">-- Choose Pilar --</option>
                                <option value="lifestyle">Lifestyle</option>
                                <option value="social">Social</option>
                                <option value="finance">Finance</option>
                                <option value="health">Health</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="form!=='Preview'">
                        <label class="col-md-3 col-form-label">Stories Cover</label>
                        <div class="col-md-9">
                            <!-- <span class="help-block">Please enter your email</span> -->
                            <input 
                                type="file" 
                                (change)="imagesPreviewImage($event)" 
                            >
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="modalForm.hide()">Close</button>
                <button type="submit" class="btn" [class]="form === 'Add' ? 'btn-success' : 'btn-info'" (click)="toSave()" *ngIf="!isLoading && form!=='Preview'">Submit</button>
                <button type="button" class="btn" [class]="form === 'Add' ? 'btn-success' : 'btn-info'" *ngIf="isLoading" disabled>Processing...</button>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
              <strong>Stories Upload</strong>
            </div>
            <div class="card-body">                
                <div class="col-6 col-sm-4 col-md mb-3 mb-xl-0 text-left">
                    <button type="button" class="btn btn-success btn-sm" (click)="viewModal(false)">Add</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="fade-in">
    <c-card>
        <c-card-body>
            <c-row class="mb-3">
                <c-col md="4" class="offset-md-8">
                <div class="input-group">
                    <div class="input-group-prepend">
                    <span class="input-group-text"><i class="cil-search"></i></span>
                    </div>
                    <input cInput
                    class="form-control"
                    [(ngModel)]="filterQuery"
                    placeholder="Search by Name"
                    />
                </div>
                </c-col>
            </c-row>
            <table
                class="table table-striped table-hover table-sm table-responsive-lg"
                [mfData]="data | storiesHeaderFilter: filterQuery"
                #mf="mfDataTable"
                [mfRowsOnPage]="10"
            >
                <thead>
                    <tr>
                        <th style="width: 5%; text-align: center;">No</th>
                        <th style="width: 25%; text-align: center;">
                            <mfDefaultSorter by="name" #sortName>
                                Name
                                <span
                                    *ngIf="sortName.isSortedByMeAsc"
                                    class="cil-arrow-top"
                                    aria-hidden="true"
                                ></span>
                                <span
                                    *ngIf="sortName.isSortedByMeDesc"
                                    class="cil-arrow-bottom"
                                    aria-hidden="true"
                                ></span>
                            </mfDefaultSorter>
                        </th>
                        <th style="width: 25%; text-align: center;">
                            <mfDefaultSorter by="type" #sortType>
                                Pilar
                                <span
                                    *ngIf="sortType.isSortedByMeAsc"
                                    class="cil-arrow-top"
                                    aria-hidden="true"
                                ></span>
                                <span
                                    *ngIf="sortType.isSortedByMeDesc"
                                    class="cil-arrow-bottom"
                                    aria-hidden="true"
                                ></span>
                            </mfDefaultSorter>
                        </th>
                        <th style="width: 20%; text-align: center;">Stories Cover</th>
                        <th style="width: 10%; text-align: center;">Add Stories Page</th>
                        <th style="width: 15%; text-align: center;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of mf.data">
                        <td style="text-align: center;">{{data.indexOf(item) + 1}}</td>
                        <td style="text-align: left;">{{ item.header_name }}</td>
                        <td style="text-align: left;">{{ item.stories_type }}</td>
                        <td style="text-align: center;">
                            <img
                                [src]="item.header_image"
                                style="height: 30px; width: 50px;"
                            />
                        </td>
                        <td style="text-align: center;">
                            <div class="btn-group" dropdown title="Add Stories Page">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-plus" viewBox="0 0 16 16" style="cursor: pointer;" (click)="viewModalPage(item)" title="Add Stories Page">
                                    <path d="M8.5 6a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V10a.5.5 0 0 0 1 0V8.5H10a.5.5 0 0 0 0-1H8.5V6z"/>
                                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                                </svg>
                            </div>
                        </td>
                        <td style="text-align: right;">
                            <div class="btn-group" dropdown title="Edit">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16" style="cursor: pointer;" (click)="viewModal(item)" title="Edit">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                            </div>
                            &nbsp;
                            <div class="btn-group" dropdown title="Delete">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16"  style="cursor: pointer;" (click)="delete(item.stories_id)" title="Delete">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                </svg>
                            </div>
                            &nbsp;
                            <div class="btn-group" dropdown title="View">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16" style="cursor: pointer;" (click)="viewModal(item,'Preview')" title="View">
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                </svg>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot style="border: none;">
                    <tr>
                        <td colspan="6">
                            <mfBootstrapPaginator></mfBootstrapPaginator>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </c-card-body>
    </c-card>
</div>
  