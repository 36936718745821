<!--<c-header [fixed]="true">-->
    <ng-container>
        <c-toggler
          class="d-md-none"
          cSidebarToggle="sidebar"
          [inHeader]="true"
        ></c-toggler>
        <c-toggler
          class="d-sm-down-none mfs-3"
          cSidebarToggle="sidebar"
          id="sidebar-toggler"
          [inHeader]="true"
        ></c-toggler>
        <c-header-brand [routerLink]="'/dashboard/useractivity'" class="d-lg-none"
            style="padding-right: 10px;">
            <img 
                src="assets/img/brand/logoda-icon.png" 
                alt="Dompet AMAN" 
                class="img-fluid"
                width="40"
                height="40">
        </c-header-brand>
        <!-- header links-->
        <c-breadcrumb
            class="border-0 m-0 px-0 px-md-3 c-subheader-nav"
            cBreadcrumbRouter
        ></c-breadcrumb>
        <!--theme toggler-->
        <c-toggler title="Toggle Light/Dark Mode" class="ml-auto" id="theme-toggler">
          <button [class]="'c-header-toggler'" type="button" (click)="toggleTheme()">
            <c-icon name="cilMoon" size="lg" class="c-d-dark-none"></c-icon>
            <c-icon name="cilSun" size="lg" class="c-d-default-none"></c-icon>
          </button>
        </c-toggler>
        <!--header dropdowns-->
        <!-- <ul class="c-header-nav">
          <app-default-header-dropdown-notifications
            class="d-md-down-none"
          ></app-default-header-dropdown-notifications>
          <app-default-header-dropdown-tasks
            class="d-md-down-none"
          ></app-default-header-dropdown-tasks>
          <app-default-header-dropdown-messages
            class="d-md-down-none"
          ></app-default-header-dropdown-messages>
          <app-default-header-dropdown-account></app-default-header-dropdown-account>
        </ul> -->
        <!--aside toggler-->
        <c-toggler cSidebarToggle="aside" title="Logout" id="aside-toggler">
          <button class="c-header-toggler c-header-nav-link" type="button" (click)="logout()">
            <c-icon name="cilAccountLogout" size="lg"></c-icon>
          </button>
        </c-toggler>
        <!-- <p>Logout</p> -->

        <!--</c-header>-->
      </ng-container>      